import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";
import { Typography } from "../components/ui/Typography";
import Helmet from "react-helmet";

const SurveyContainer = styled(Container)`
  padding: 0 15vw 5rem;
  @media screen and (max-width: 991px) {
    padding: 2rem 10vw 3rem;
  }
`;

const SurveyPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SurveyContainer fluid>
        <Row>
          <Col>
            <Typography.H1>Knowledge Pipeline Participant Survey</Typography.H1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.H3 style={{ padding: "1rem 0 2rem" }}>
              Your feedback is valuable! We want to hear from you on how we can
              continue to better our education program.
            </Typography.H3>
          </Col>
        </Row>
        <Row>
          <Col>
            <HubspotForm
              portalId="21124615"
              formId="8096b5f4-42d0-4978-b6db-9a0d3c571d46"
            />
          </Col>
        </Row>
      </SurveyContainer>
    </>
  );
};

export default SurveyPage;
